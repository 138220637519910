<template>
  <template v-if="deviceType === 'desktop'">
    <div class="row fm-background-main-color-desktop">
      <div class="col-3"></div>
      <div class="col-6">
        <div class="container-fluid">
          <InfoPage
            v-bind:title="'Płatność zakończona pomyślnie'"
            v-bind:message1="'Możesz teraz rozpocząć naukę!'"
            v-bind:image="'/images/icons/smiling-face-with-sunglasses-big.svg'">
            <button class="btn btn-primary w-100 fm-login-button fm-button-main-background-color fm-button" v-on:click="this.$router.push('/funbook')">
              Przejdź<br> do kursu
            </button>
          </InfoPage>
        </div>
      </div>
      <div class="col-3"></div>
    </div>
  </template>

  <template v-if="deviceType === 'mobile'">
    <div class="container vh-100 fm-background-main-color">
      <InfoPage
        v-bind:title="'Płatność zakończona pomyślnie'"
        v-bind:message1="'Możesz teraz rozpocząć naukę!'"
        v-bind:image="'/images/icons/smiling-face-with-sunglasses-big.svg'">
      </InfoPage>
      <button class="btn btn-primary w-100 fm-login-button fm-button-main-background-color fm-button" v-on:click="this.$router.push('/funbook')">Przejdź<br> do kursu</button>
    </div>
  </template>

</template>

<script>
import InfoPage from '../components/InfoPage';
export default {
  name: 'PaymentSuccess',
  components: { InfoPage },
  data() {
    return {
      deviceType: 'desktop'
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();
  },
  unmounted() {
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
    }
  }
};
</script>

<style scoped>
.fm-background-main-color-desktop {
  background-color: var(--cosmic-latte);
  min-height: 100vh;
}
.fm-button {
  margin-top: 5rem;
  height: 9rem;
  font-size: 1.75rem;
}
</style>
